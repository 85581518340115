import React, {useState} from 'react';
import './AccountPassword.css';
import { changePassword } from '../../constants/routes';
import { useNavigate } from 'react-router-dom';
// import { getToken } from '../../utils/auth';
import axios from 'axios';
import { toast } from 'react-toastify';
import LoadingScreen from '../LoadingScreen/LoadingScreen';

function AccountPassword() {

  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const handlePasswordUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    // var token = getToken();
    try {
        const headers = {
            // 'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        };
      const response = await axios.post(
        changePassword,
        { oldPassword, newPassword, confirmPassword },
        {headers, withCredentials: true},
    );
      if (response.status) {
        toast.success("Password changed successful!");
        navigate('/');
      } else {
        toast.error(`${response.data.message}`);
      }
    } catch (error) {
      toast.error(`${error.response.data.message}`);
    } finally {
      setLoading(false);
    }
  }

  if(loading) {
    return <LoadingScreen />;
  }

    return(
        <div className="account-info">
        <h2>Account Settings</h2>
        <div className="profile-info-section">
          <h3>Change Password</h3>
          <form>
            <div className="form-group">
              <label>Old Password:</label>
              <input type="text" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} placeholder="Enter old password" />
            </div>
            <div className="form-group">
              <label>New Password:</label>
              <input type="text" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder="Enter new password" />
            </div>
            <div className="form-group">
              <label>Confirm Password</label>
              <input type="text" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm new password" />
            </div>
          </form>
          <div className="save-profile-section">
          <button className="save-profile-button" onClick={handlePasswordUpdate}>Save Changes</button>
        </div>
        </div>
      </div>
    );
}

export default AccountPassword;