// src/pages/NotFoundPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import './NotFoundScreen.css';
import NotFoundImage from '../../assets/not-found.png';

const NotFoundPage = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <div className="not-found-404">
          <img src={NotFoundImage} alt="404" />
        </div>
        <h1 className='not-found-h1'>Oops!</h1>
        <p>We can't seem to find the page you are looking for</p>
        <Link to="/" className="back-home-button">
          Back to Homepage
        </Link>
      </div>
      {/* <div className="social-links">
        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
          <img src="path_to_instagram_icon" alt="Instagram" />
        </a>
        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
          <img src="path_to_facebook_icon" alt="Facebook" />
        </a>
        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
          <img src="path_to_linkedin_icon" alt="LinkedIn" />
        </a>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
          <img src="path_to_twitter_icon" alt="Twitter" />
        </a>
        <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
          <img src="path_to_youtube_icon" alt="YouTube" />
        </a>
      </div> */}
    </div>
  );
};

export default NotFoundPage;
