import React from 'react';
import './CreateEventSection.css';
// import EventImage from '../../assets/image3.png';
import { isAuthenticated } from '../../utils/auth';
import { useNavigate } from 'react-router-dom';

const CreateEventSection = () => {

  const navigate = useNavigate();

  const handleClick = () => {
    isAuthenticated() ? navigate('/create-event') : navigate('/register')
  }

  return (
    <div className="create-event-banner">
      <div className="content">
        <h2>Create an event with Entertico</h2>
        <p>Got a show, event, activity or a great experience? Partner with us & get listed on Entertico</p>
        <button className="create-event-button" onClick={handleClick}>Create Event</button>
      </div>
    </div>
  );
};

export default CreateEventSection;
