import React, { createContext, useContext, useState, useEffect } from 'react';
import { getToken, removeToken } from '../utils/auth';

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const token = getToken();
        if(token) {
            setUser({token});
        }
    }, []);

    const logout = () => {
        removeToken();
        setUser(null);
    };

    return(
        <AuthContext.Provider value={{user, setUser, logout}}>
            {children}
        </AuthContext.Provider>
    );
};