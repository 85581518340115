import React from 'react';
import './privacy-policy.css';

const PrivacyPolicy = () => {
    const sections = [
        { 
            id: 'section1', 
            title: '1. What Information Do We Collect?', 
            content: `
                <p>Personal information you disclose to us</p>
        
                <p>In Short: We collect personal information that you provide to us.</p>
        
                <p>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>
        
                <p><strong>Personal Information Provided by You.</strong> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
                <ul>
                    <li>names</li>
                    <li>phone numbers</li>
                    <li>email addresses</li>
                    <li>passwords</li>
                    <li>debit/credit card numbers</li>
                </ul>
                
                <p><strong>Sensitive Information.</strong> We do not process sensitive information.</p>
        
                <p><strong>Payment Data.</strong> We may collect data necessary to process your payment if you choose to make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is handled and stored by Paystack. You may find their privacy notice link(s) here: <a href="https://paystack.com/terms">https://paystack.com/terms</a>.</p>
        
                <p><strong>Application Data.</strong> If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:</p>
                <ul>
                    <li><strong>Mobile Device Access.</strong> We may request access or permission to certain features from your mobile device, including your mobile device's calendar, camera, and other features. If you wish to change our access or permissions, you may do so in your device's settings.</li>
                    <li><strong>Mobile Device Data.</strong> We automatically collect device information (such as your mobile device ID, model, and manufacturer), operating system, version information and system configuration information, device and application identification numbers, browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our application(s), we may also collect information about the phone network associated with your mobile device, your mobile device’s operating system or platform, the type of mobile device you use, your mobile device’s unique device ID, and information about the features of our application(s) you accessed.</li>
                    <li><strong>Push Notifications.</strong> We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device's settings.</li>
                </ul>
        
                <p>This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.</p>
        
                <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>
        
                <p><strong>Information automatically collected</strong></p>
        
                <p>In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.</p>
        
                <p>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</p>
        
                <p>Like many businesses, we also collect information through cookies and similar technologies.</p>
        
                <p>The information we collect includes:</p>
                <ul>
                    <li><strong>Log and Usage Data.</strong> Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps'), and hardware settings).</li>
                    <li><strong>Device Data.</strong> We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</li>
                    <li><strong>Location Data.</strong> We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.</li>
                </ul>
        
                <p><strong>Google API</strong></p>
        
                <p>Our use of information received from Google APIs will adhere to Google API Services User Data Policy, including the Limited Use requirements.</p>
            ` 
        },
        { 
            id: 'section2', 
            title: '2. How Do We Process Your Information?', 
            content: `
                <p>In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</p>
                
                <p>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</p>
                <ul>
                    <li>To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.</li>
                    <li>To deliver and facilitate delivery of services to the user. We may process your information to provide you with the requested service.</li>
                    <li>To respond to user inquiries/offer support to users. We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</li>
                    <li>To send administrative information to you. We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.</li>
                    <li>To send you marketing and promotional communications. We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more information, see <a href="#section7">WHAT ARE YOUR PRIVACY RIGHTS?</a> below.</li>
                    <li>To protect our Services. We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.</li>
                    <li>To comply with our legal obligations. We may process your information to comply with our legal obligations, respond to legal requests, and exercise, establish, or defend our legal rights.</li>
                </ul>
            ` 
        },
        { 
            id: 'section3', 
            title: '3. When and With Whom Do We Share Your Personal Information?', 
            content: `
                <p>In Short: We may share information in specific situations described in this section and/or with the following third parties.</p>
        
                <p><strong>Vendors, Consultants, and Other Third-Party Service Providers.</strong> We may share your data with third-party vendors, service providers, contractors, or agents ('third parties') who perform services for us or on our behalf and require access to such information to do that work.</p>
        
                <p>The third parties we may share personal information with are as follows:</p>
                <ul>
                    <li><strong>Cloud Computing Services:</strong> Microsoft Azure</li>
                    <li><strong>Web and Mobile Analytics:</strong> Google Analytics</li>
                </ul>
        
                <p>We also may need to share your personal information in the following situations:</p>
                <ul>
                    <li><strong>Business Transfers:</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                    <li><strong>When we use Google Maps Platform APIs:</strong> We may share your information with certain Google Maps Platform APIs (e.g. Google Maps API, Places API). Google Maps uses GPS, Wi-Fi, and cell towers to estimate your location. GPS is accurate to about 20 meters, while Wi-Fi and cell towers help improve accuracy when GPS signals are weak, like indoors. This data helps Google Maps provide directions, but it is not always perfectly precise.</li>
                    <li><strong>Affiliates:</strong> We may share your information with our affiliates, in which case we will require those affiliates to honour this Privacy Notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.</li>
                    <li><strong>Business Partners:</strong> We may share your information with our business partners to offer you certain products, services, or promotions.</li>
                </ul>
            ` 
        },
        { 
            id: 'section4', 
            title: '4. Do We Use Cookies and Other Tracking Technologies?', 
            content: `
                <p>In Short: We may use cookies and other tracking technologies to collect and store your information.</p>
        
                <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when you interact with our Services. Some online tracking technologies help us maintain the security of our Services and your account, prevent crashes, fix bugs, save your preferences, and assist with basic site functions.</p>
        
                <p>We also permit third parties and service providers to use online tracking technologies on our Services for analytics and advertising, including to help manage and display advertisements, to tailor advertisements to your interests, or to send abandoned shopping cart reminders (depending on your communication preferences). The third parties and service providers use their technology to provide advertising about products and services tailored to your interests which may appear either on our Services or on other websites.</p>
        
                <p>Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.</p>
            ` 
        },
        { 
            id: 'section5', 
            title: '5. How Long Do We Keep Your Information?', 
            content: `
                <p>In Short: We keep your information for as long as necessary to fulfil the purposes outlined in this Privacy Notice unless otherwise required by law.</p>
        
                <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this Privacy Notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.</p>
        
                <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
            ` 
        },
        { 
            id: 'section6', 
            title: '6. How Do We Keep Your Information Safe?', 
            content: `
                <p>In Short: We aim to protect your personal information through a system of organisational and technical security measures.</p>
        
                <p>We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.</p>
        
                <p>Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</p>
            ` 
        },
        { 
            id: 'section7', 
            title: '7. What Are Your Privacy Rights?', 
            content: `
                <p>In Short: You may review, change, or terminate your account at any time, depending on your country, province, or state of residence.</p>
        
                <p><strong>Withdrawing your consent:</strong> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below.</p>
        
                <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>
        
                <p><strong>Opting out of marketing and promotional communications:</strong> You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, or by contacting us using the details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.</p>
        
                <p><strong>Account Information:</strong></p>
                <p>If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
                <ul>
                    <li>Contact us using the contact information provided.</li>
                </ul>
                <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</p>
        
                <p><strong>Cookies and similar technologies:</strong> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services.</p>
        
                <p>If you have questions or comments about your privacy rights, you may email us at <a href="mailto:support@entertico.com">support@entertico.com</a>.</p>
            ` 
        },
        { 
            id: 'section8', 
            title: '8. Controls for Do-Not-Track Features', 
            content: `
                <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognising and implementing DNT signals has been finalised.</p>
        
                <p>As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Notice.</p>
            ` 
        },
        { 
            id: 'section9', 
            title: '9. Do We Make Updates to This Notice?', 
            content: `
                <p>In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>
        
                <p>We may update this Privacy Notice from time to time. The updated version will be indicated by an updated 'Revised' date at the top of this Privacy Notice. If we make material changes to this Privacy Notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this Privacy Notice frequently to be informed of how we are protecting your information.</p>
            ` 
        },
        { 
            id: 'section10', 
            title: '10. How Can You Contact Us About This Notice?', 
            content: `
                <p>If you have questions or comments about this notice, you may email us at <a href="mailto:emmanueltuksa@entertico.com">emmanueltuksa@entertico.com</a> or contact us by post at:</p>
        
                <p>Entertico LTD<br/>
                B5, Loyong Crescent, Rayfield Jos<br/>
                Jos, Plateau 930103<br/>
                Nigeria</p>
            ` 
        },
        { 
            id: 'section11', 
            title: '11. How Can You Review, Update, or Delete the Data We Collect From You?', 
            content: `
                <p>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, details about how we have processed it, correct inaccuracies, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. To request to review, update, or delete your personal information, please visit: <a href="mailto:support@entertico.com">support@entertico.com</a>.</p>
            ` 
        },
    ];

    const scrollToSection = (id) => {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="privacy-policy">
            {/* Header */}
            <header className='privacy-header'>
                <h1>Privacy Policy</h1>
                <p>Last updated: November 24, 2024</p>
            </header>
            <div>
                <p>This Privacy Notice for Entertico LTD (doing business as Entertico) ('we', 'us', or 'our'), describes how and why we might access, collect, store, use, and/or share ('process') your personal information when you use our services ('Services'), including when you:</p>

                <ul>
                    <li>Visit our website at <a href="http://www.entertico.com">http://www.entertico.com</a>, or any website of ours that links to this Privacy Notice</li>
                    <li>Download and use our mobile application (Entertico), or any other application of ours that links to this Privacy Notice</li>
                    <li>Use Entertico, an event management system that connects organizers to their audience seamlessly, facilitating the creation and management of events, promotion of events, and the purchase of event tickets</li>
                    <li>Engage with us in other related ways, including any sales, marketing, or events</li>
                </ul>

                <p>Questions or concerns? Reading this Privacy Notice will help you understand your privacy rights and choices. We are responsible for making decisions about how your personal information is processed. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at <a href="mailto:support@entertico.com">support@entertico.com</a>.</p>
            </div>

            {/* Summary of Key Points */}
            <section id="summary">
                <h2>SUMMARY OF KEY POINTS</h2>
                <p>This summary provides key points from our Privacy Notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for.</p>
                <ul>
                    <li><strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. <a href="#section1">Learn more about personal information you disclose to us.</a></li>
                    <li><strong>Do we process any sensitive personal information?</strong> Some of the information may be considered 'special' or 'sensitive' in certain jurisdictions, for example your racial or ethnic origins, sexual orientation, and religious beliefs. We do not process sensitive personal information.</li>
                    <li><strong>Do we collect any information from third parties?</strong> We do not collect any information from third parties.</li>
                    <li><strong>How do we process your information?</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. <a href="#section2">Learn more about how we process your information.</a></li>
                    <li><strong>In what situations and with which parties do we share personal information?</strong> We may share information in specific situations and with specific third parties. <a href="#section3">Learn more about when and with whom we share your personal information.</a></li>
                    <li><strong>How do we keep your information safe?</strong> We have adequate organisational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. <a href="#section6">Learn more about how we keep your information safe.</a></li>
                    <li><strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. <a href="#section7">Learn more about your privacy rights.</a></li>
                    <li><strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by visiting <a href="mailto:support@entertico.com">support@entertico.com</a>, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</li>
                    <li><strong>Want to learn more about what we do with any information we collect?</strong> Review the Privacy Notice in full.</li>
                </ul>
            </section>

            {/* Table of Contents */}
            <nav className="toc">
                <h2>Table of Contents</h2>
                <ul>
                    {sections.map((section) => (
                        <li key={section.id}>
                            <button onClick={() => scrollToSection(section.id)}>{section.title}</button>
                        </li>
                    ))}
                </ul>
            </nav>
            

            {/* Content Sections */}
            <main className="content">
            {/* What Information Do We Collect? */}
                {sections.map((section) => (
                    <section key={section.id} id={section.id}>
                        <h3>{section.title}</h3>
                        <div dangerouslySetInnerHTML={{ __html: section.content }} />
                    </section>
                ))}
            </main>
        </div>
    );
};

export default PrivacyPolicy;