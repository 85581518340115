// Sidebar.js
import React from 'react';
import './AccountSidebar.css';

const AccountSidebar = ({ setSelectedSection, selectedSection }) => {
  return (
    <div className="account-settings-sidebar">
        <h2>Account Settings</h2>
        <ul>
          <li 
          className={selectedSection === 'AccountInfo' ? 'active' : ''}
          onClick={() => setSelectedSection('AccountInfo')}>Account Info</li>
          <li className={selectedSection === 'AccountPassword' ? 'active' : ''}
            onClick={() => setSelectedSection('AccountPassword')}>Change Password</li>
        </ul>
      </div>
  );
};

export default AccountSidebar;
