import NotFoundPage from "../components/ErrorScreens/NotFoundScreen";
import Footer from "../components/Footer/Footer";

export default function NotFoundScreen() {
    return (
        <>
            <NotFoundPage />
            <Footer/>
        </>
    );
}