import React, { useEffect } from 'react';
import './HeroSection.css';
import startUpBadge from '../../assets/startup_badge_dark.png';

function HeroSection() {
  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector('.hero-section');
      if (section.getBoundingClientRect().top < window.innerHeight) {
        section.classList.add('animate'); // Adds animation class on scroll
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="hero-section">
      <div className="hero-content">
        <h1>Don't miss out!</h1>
        <h2>Explore the <span className="vibrant">vibrant events</span> happening locally and nationwide.</h2>
      </div>
      <div className="startup">
        <img src={startUpBadge} alt="startup-badge" />
      </div>
    </div>
  );
}

export default HeroSection;
