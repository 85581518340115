import Header from '../components/Header/Header';
import PrivacyPolicy from '../components/Privacy/PrivacyPolicy';
import Footer from '../components/Footer/Footer';

export default function PrivacyPage() {
    return(
    <>
      <Header />
      <PrivacyPolicy />
      <Footer />
    </>
    );
}