// components/SubscribeSection.js
import React, { useState } from 'react';
import './SubscribeSection.css';
import axios from 'axios';
// import { setToken } from '../../utils/auth';
import { toast } from 'react-toastify';
import { subscribe } from '../../constants/routes';

const SubscribeSection = () => {

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubscription = async (e) => {
    e.preventDefault();

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      toast.error('Please enter a valid email address');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        subscribe, null, { params: {email} } 
      );
      if (response.status) {
        toast.success("Subscribed to newsletter successfully.");
        // const { token } = response.data;
        // setToken(token);
        // navigate('/');
      } else {
        toast.error(`${response.data.message}`);
      }
    } catch (error) {
      toast.error(`Newsletter subscription failed: ${error.response.data.message}`);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="subscribe-section">
      <div className="content">
        <h2>Subscribe to our Newsletter</h2>
        <p>Receive our weekly newsletter & updates with new events from your favourite organizers & venues.</p>
        <form className="subscribe-form" onSubmit={handleSubscription}>
          <input type="email" placeholder="Enter your e-mail address"
          value={email} onChange={(e) => setEmail(e.target.value)} />
          <button type="submit">Subscribe</button>
        </form>
      </div>
    </div>
  );
};

export default SubscribeSection;
