import React, { useState } from 'react';
import './SignUpScreen.css';
import { useNavigate } from 'react-router-dom';
import entertico from '../../assets/entertico.png';
import axios from 'axios';
// import { setToken } from '../../utils/auth';
import { toast } from 'react-toastify';
import { register } from '../../constants/routes';

const SignUpScreen = () => {

  const [organizerName, setOrganizerName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [about, setAbout] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // const handleSignInClick = () => {
  //   navigate('/login');
  // };

  const handleSignUp = async (e) => {
    e.preventDefault();

    if (!organizerName || !email || !password || !confirmPassword || !about) {
      toast.error('Please fill in all fields');
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      toast.error('Please enter a valid email address');
      return;
    }

    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        register, { organizerName, about, email, password }
      );
      if (response.status) {
        toast.success("Registration successful! Download the mobile app to continue.");
        // const { token } = response.data;
        // setToken(token);
        navigate('/');
      } else {
        toast.error(`${response.data.message}`);
      }
    } catch (error) {
      toast.error(`Registration failed: ${error.response.data.message}`);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="signup-container">
      <div className="signup-left">
        {/* <img src={BackgroundImage} alt="Welcome back" className="background-image" /> */}
        <div className="overlay">
          {/* <h2>Welcome back</h2>
          <p>To keep connected with us provide us with your information</p>
          <button className="signin-button" onClick={handleSignInClick}>Signin</button> */}
        </div>
      </div>
      <div className="signup-right">
        <h2><img src={entertico} alt='entertico' className='logo'/><span className="highlight">Entertico</span></h2>
        <h3>Sign up to create events</h3>
        <form className="signup-form" onSubmit={handleSignUp}>
          <label>Organizer Name</label>
          <input 
            type="text" 
            placeholder="Enter  organizer name"
            value={organizerName}
            onChange={(e) =>setOrganizerName(e.target.value)}
          />
          <label>Email</label>
          <input
            type="text"
            placeholder="Enter email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label>About</label>
          <input
            type="text"
            placeholder="Tell us briefly about yourself"
            value={about}
            onChange={(e) => setAbout(e.target.value)}
          />
          <label>Password</label>
          <input
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <label>Confirm Password</label>
          <input
            type="password"
            placeholder="Confirm your password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <button type="submit" className="signup-button">
            {loading ? <div className="spinner"></div> : 'Sign Up'}
          </button>
          {/* <p>Or</p>
          <button className="google-signup-button">
            <img src={GoogleLogo} alt="Google logo" className="google-logo" />
            Sign up with Google
          </button> */}
        </form>
      </div>
    </div>
  );
};

export default SignUpScreen;
