// components/EventCard.js
import React from 'react';
import { format } from 'date-fns';
import './EventCard.css';
// import { Link } from 'react-router-dom';

function EventCard({ event }) {

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return {
      day: format(date, 'dd'),
      month: format(date, 'MMM').toUpperCase(), // Convert month to uppercase
    };
  };

  const { day, month } = formatDate(event.date);

  const { eventName, description, banner } = event;

  return (
    <div key={event.id} className="event-card">
      {/* // <Link to={`/event/${event.uuid}`} className="event-card"> */}
      <img src={banner} alt={eventName} className='event-card-image' />
      <div className="event-card-details">
        <div className="event-card-date">
          <span className="event-card-month">{month}</span>
          <span className="event-card-day">{day}</span>
        </div>
        <div className="event-card-info">
          <h3 className="event-card-title">{eventName}</h3>
          <p className="event-card-description">{description}</p>
        </div>
      </div>
    {/* </Link> */}
    </div>
  );
}

export default EventCard;
