const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: '2px',
      border: '1px solid #ccc',
      borderRadius: '5px',
      fontSize: '1rem',
      marginBottom: '15px',
      backgroundColor: 'transparent',
      width: '100%',
      maxWidth: '500px'
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#ccc' : state.isFocused ? '#eee' : '#fff',
      color: '#000',
      padding: '10px',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '5px',
      marginTop: '0',
      border: '1px solid #ccc',
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#000',
    })
  };
  
  export default customStyles;